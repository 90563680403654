<template></template>

<script></script>

<style></style>
<style scoped>
div,
input,
textarea {
	box-sizing: border-box;
}
.page_box_add_community {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}
.page_box_body {
	overflow: auto;
	flex: 1;
}
.page_box_footer {
	padding: 10px 10px 0 10px;
	text-align: right;
}
/* 表单 */

.z_form_item_row {
	padding: 10px 0;
}
.z_form_item {
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.z_form_item_label {
	width: 100px;
	text-align: right;
	padding-right: 10px;
}
.z_form_item_value {
	flex: 1;
}
.z_form input {
	height: 30px;
	width: 100%;
}
.z_form .el-select {
	height: 30px;
	width: 100%;
}
.z_form textarea {
	height: 80px;
	width: 100%;
	padding: 5px;
}

.z_form .el-date-editor.el-input {
	width: 100%;
}
.z_form [class*='el-col-'] {
	padding: 0 10px;
}
</style>
<style>
.z_form .el-form-item {
	margin-bottom: 0;
}
.z_form .el-form-item__content {
	margin-left: 0 !important;
}
</style>
<template>
	<div class="page_box_add_community">
		<div class="page_box_header"></div>
		<div class="page_box_body">
			<div class="z_form">
				<el-form label-width="80px" :model="form" :rules="rules" ref="form">
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="12">
							<el-form-item prop="name">
								<div class="z_form_item">
									<div class="z_form_item_label">优惠类型</div>
									<div class="z_form_item_value">
										<div class="z_form_item_value">
											<el-select v-model="form.discount_type" placeholder="请选择">
												<el-option key="1" label="折扣" value="1"></el-option>
												<el-option key="2" label="金额" value="2"></el-option>
											</el-select>
										</div>
									</div>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12" v-if="form.discount_type == 1">
							<el-form-item prop="name">
								<div class="z_form_item">
									<div class="z_form_item_label">折扣</div>
									<div class="z_form_item_value"><el-input placeholder="必填:如9.5折" type="text" v-model="form.discount"></el-input></div>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12" v-if="form.discount_type == 2">
							<div class="z_form_item">
								<div class="z_form_item_label">优惠金额</div>
								<div class="z_form_item_value"><el-input placeholder="必填 (元)" type="text" v-model="form.money"></el-input></div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="12">
							<el-form-item prop="name">
								<div class="z_form_item">
									<div class="z_form_item_label">优惠劵名称</div>
									<div class="z_form_item_value"><el-input placeholder="必填" type="text" v-model="form.name"></el-input></div>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">使用条件</div>
								<div class="z_form_item_value"><el-input placeholder="满足缴纳多少个月使用优惠劵" v-model="form.months"></el-input></div>
							</div>
						</el-col>
					</el-row>

					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">活动开始日期</div>
								<div class="z_form_item_value">
									<el-date-picker value-format="yyyy-MM-dd" v-model="form.start_date" type="date" placeholder="选择日期"></el-date-picker>
								</div>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="z_form_item">
								<div class="z_form_item_label">活动截止日期</div>
								<div class="z_form_item_value">
									<el-date-picker value-format="yyyy-MM-dd" v-model="form.end_date" type="date" placeholder="选择日期"></el-date-picker>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="12">
							<el-form-item prop="name">
								<div class="z_form_item">
									<div class="z_form_item_label" style="width: 150px;">最大使用次数限制</div>
									<div class="z_form_item_value">
										<div class="z_form_item_value">
											<el-select v-model="form.is_use_num" placeholder="请选择">
												<el-option key="1" label="限制" value="1"></el-option>
												<el-option key="2" label="不限制" value="2"></el-option>
											</el-select>
										</div>
									</div>
								</div>
							</el-form-item>
						</el-col>

						<el-col :span="12" v-if="form.is_use_num == 1">
							<div class="z_form_item">
								<div class="z_form_item_label">最大使用次数</div>
								<div class="z_form_item_value"><el-input placeholder="" v-model="form.max_use_num"></el-input></div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="8">
							<el-form-item prop="name">
								<div class="z_form_item">
									<div class="z_form_item_label">缴费月份限制</div>
									<div class="z_form_item_value">
										<div class="z_form_item_value">
											<el-select v-model="form.is_cost_time" placeholder="请选择">
												<el-option key="1" label="使用时间限制" value="1"></el-option>
												<el-option key="2" label="不限时间" value="2"></el-option>
											</el-select>
										</div>
									</div>
								</div>
							</el-form-item>
						</el-col>
						<el-col :span="8" v-if="form.is_cost_time == 1">
							<div class="z_form_item">
								<div class="z_form_item_label">缴费开始月份</div>
								<div class="z_form_item_value">
									<el-date-picker format="yyyy-MM" value-format="yyyy-MM" v-model="form.cost_start_date" type="month" placeholder="选择日期"></el-date-picker>
								</div>
							</div>
						</el-col>
						<el-col :span="8" v-if="form.is_cost_time == 1">
							<div class="z_form_item">
								<div class="z_form_item_label">缴费截止月份</div>
								<div class="z_form_item_value">
									<el-date-picker format="yyyy-MM" value-format="yyyy-MM" v-model="form.cost_end_date" type="month" placeholder="选择日期"></el-date-picker>
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="24">
							<div class="z_form_item">
								<div class="z_form_item_label">备注</div>
								<div class="z_form_item_value">
									<el-input type="textarea" v-model="form.remark"></el-input>
									<!-- <textarea rows="3" cols="20"></textarea> -->
								</div>
							</div>
						</el-col>
					</el-row>
					<el-row :gutter="0" class="z_form_item_row">
						<el-col :span="24">
							<el-form-item prop="name">
								<div class="z_form_item">
									<div class="z_form_item_label">生效项目</div>
									<div class="z_form_item_value">
										<el-checkbox-group v-model="form.item_ids">
											<el-checkbox v-for="item in projectOptions" :value="item.id" :label="item.id">{{ item.name }}</el-checkbox>
										</el-checkbox-group>
									</div>
								</div>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</div>
		<div class="page_box_footer">
			<el-button>取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="onSubmit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
var _this;
export default {
	props: ['upPageParams'],
	data() {
		return {
			form: {
				name: '',
				discount_type: '1',
				discount: '',
				money: '',
				item_ids: [],
				start_date: '',
				end_date: '',
				months: '',
				is_cost_time: '1',
				cost_start_date: '',
				cost_end_date: '',
				is_use_num: '1',
				max_use_num: '',
				remark: ''
			},
			rules: null,
			btnSaveLoading: false,
			projectOptions: []
		};
	},
	created: function() {
		_this = this;
		_this.apiGetOpentions();
		console.log(_this.upPageParams);
	},
	methods: {
		/** ->  获取项目集合
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-24 12:07:28 */
		apiGetOpentions() {
			_this
				._postApi('/wy/chargeItem/getItemList', {
					residence_id: _this.upPageParams.community.id,
					type: 1
				})
				.then(function(res) {
					console.log(res);
					_this.projectOptions = res.data;
				})
				.catch(function(erre) {});
		},

		// _** 提交保存
		onSubmit() {
			_this.btnSaveLoading = true;
			var params = _this.form;
			params.residence_id = _this.upPageParams.community.id;
			_this
				._postApi('/wy/coupon/create', params)
				.then(res => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent', {
							type: 'suceess'
						});
					} else {
						_this.btnSaveLoading = false;
						_this
							.$confirm(res.msg, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							})
							.then(() => {})
							.catch(() => {});
					}
				})
				.catch(err => {});
		}
	},
	watch: {
		form: {
			handler(val, oldVal) {
				var str = '使用条件:';
				var str1 = '';
				var str2 = '';
				if(val.months !='' && val.months !=0){
					str1 = '需连续缴费'+ val.months +'个月以上;'
				}else{
					str1 = ''
				}
				if(val.is_cost_time == 1){
					str2 = '缴费月份需在:'+val.cost_start_date +'-' +val.cost_end_date +'月之间;'
				}else{
					str2 = '';
				}
				_this.form.remark = str + str1 +str2
			},
			deep: true 
		}
	}
};
</script>
