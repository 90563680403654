<style scoped>
	.tpm_box{width: 100%;height: 100%;display: flex;flex-direction: column;}
	.tem_body{flex: 1;width: 100%;}
	.tem_header{padding: 5px;display: flex;align-items: center;};
	.tem_body_contair{height: 100%;width: 100%;}
	.tem_footer{text-align: right;padding: 5px;}
	.mb{padding: 0 20px 0 10px;}
	/* 弹窗 */
	.el-dialog__wrapper{position: absolute;left: 0;top: 0;display: flex;align-items: center;overflow: hidden;}
	.el-dialog.my_dialog{margin-top: 0;}
	.table--btn{margin: 0 10px;cursor: pointer;white-space: nowrap;display: inline-block;}
	.table--btn.delete{color: red;}
	.table--btn.edit{color: blue;}
	.info_pop_item{display: flex;padding: 5px 0;}
	.info_pop_item-lable{padding-right: 10px;color: #BCBEC2;}
</style>
<style>
	table{box-sizing: border-box;}
	/* 用来设置当前页面element全局table 选中某行时的背景色*/
	#p_20202231350 .el-table__body tr.current-row>td{background-color: #FFF!important;color: #333;}
	.mb .el-breadcrumb__inner{font-weight: bold!important;font-size: 1.1rem;}
	.el-table__expanded-cell[class*=cell]{padding: 5px;background-color: rgb(175,246,192);}
	.el-table .el-table__expanded-cell:hover{background-color: rgb(175,246,192)!important;}
	.el-table .tableRowClassName{background: oldlace;}
</style>
<template>
	<div class="tpm_box" id="p_20202231350" style="">
		<div class="tem_header">
			<el-button type="warning" size="small" @click="openDialogAdd">添加优惠券<i class="el-icon-circle-plus-outline"></i></el-button>
			<el-button type="danger" size="small" @click="thisReload">刷新<i class="el-icon-refresh-right"></i></el-button>
		</div>
		<div class="tem_body" id="tem_body">
			<div class="tem_body_contair" id="table_box" v-loading="tableLoading" v-if="!tableLoading" style="width: 78vw;">
				<el-table :data="tableData" :height="tableHeight" border highlight-current-row row-key="id">
					<el-table-column fixed prop="name" label="名称" width="150"></el-table-column>
					<el-table-column prop="qf_rq" label="类型" width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.discount_type == 1">折扣</span>
							<span v-if="scope.row.discount_type == 2">金额</span>
						</template>
					</el-table-column>
					<el-table-column prop="build.name" label="优惠" width="100">
						<template slot-scope="scope">
							<span v-if="scope.row.discount_type == 1">{{scope.row.discount}}折</span>
							<span v-if="scope.row.discount_type == 2">{{scope.row.money}}元</span>
						</template>
					</el-table-column>
					<el-table-column prop="item_ids_text" label="生效项目" width="150"></el-table-column>
					<el-table-column prop="start_date" label="开始时间" width="100"></el-table-column>
					<el-table-column prop="end_date" label="结束时间" width="100"></el-table-column>
					<el-table-column prop="end_date" label="使用条件" width="">
						<template slot-scope="scope">
							<span>续交费</span>
							<span>{{scope.row.months}}</span>
							<span>个月以上</span>
						</template>
					</el-table-column>
					<el-table-column prop="remark" label="状态" width="100">
						<template slot-scope="scope">
							<div class="table--btn delete" v-if="scope.row.status == 99">禁用</div>
							<div class="table--btn edit" v-if="scope.row.status == 1">正常</div>
						</template>
					</el-table-column>
					<el-table-column prop="" label="" width="">
						<template slot-scope="scope">
							<el-row>
								<el-popover placement="right" width="400" trigger="hover">
									<div class="info_pop">
										<div class="info_pop_item">
											<div class="info_pop_item-lable">备注 </div>
											<div class="info_pop_item-value">{{scope.row.remark}}</div>
										</div>
										<div class="info_pop_item">
											<div class="info_pop_item-lable">使用次数限制/已使用" </div>
											<div class="info_pop_item-value">
												<span v-if="scope.row.is_use_num == 2">无限制/{{scope.row.use_num}}次</span>
												<span v-if="scope.row.is_use_num == 1">{{scope.row.max_use_num}}/{{scope.row.use_num}}次</span>
											</div>
										</div>
										<div class="info_pop_item">
											<div class="info_pop_item-lable">缴费时间限制 </div>
											<div class="info_pop_item-value">
												<div v-if="scope.row.is_use_num == 1">
													<div><span>开始：</span> {{scope.row.cost_start_date}}</div>
													<div><span>结束：</span> {{scope.row.cost_end_date}}</div>
												</div>
												<div v-if="scope.row.is_use_num == 2">无限制</div>
											</div>
										</div>
									</div>
									<el-button type="text" slot="reference">查看详情</el-button>
								</el-popover>
								<el-button size="mini" type="text" @click="deleteA(scope.row)" style="color: red;margin-left:10px;">删除</el-button>
								<el-button size="mini" type="primary" v-if="scope.row.status == 99" :loading="elButtnLoad && clickRowId == scope.row.id"
								 @click="apiBan(scope.row)">启用</el-button>
								<el-button size="mini" type="danger" v-if="scope.row.status == 1" :loading="elButtnLoad && clickRowId == scope.row.id"
								 @click="apiBan(scope.row)">禁用</el-button>
							</el-row>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="tem_footer">
			<el-pagination @current-change="handleCurrentChange" :current-page="pageNumber" :page-sizes="[20]" :page-size="pageSize"
			 layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
			</el-pagination>
		</div>

		<!-- 添加小区弹窗 -->
		<el-dialog custom-class="my_dialog" width="60%" top="none" title="添加优惠券" :visible.sync="addDialog" destroy-on-close>
			<add v-if="addDialog" :upPageParams="upPageParams" v-on:childrenEvent="handlerChildrenEvent"></add>
		</el-dialog>

	</div>
</template>

<script>
	import add from './add.vue'
	var _this;
	export default {
		components: {
			add
		},
		props: ['upPageParams'],
		data() {
			return {
				tableLoading: true,
				pageNumber: 1,
				pageSize: 20,
				pageTotal: 0,
				tableData: [],
				addDialog: false, //1_添加
				elButtnLoad: false,
				clickRowId: 0
			}
		},
		created: function() {
			_this = this;
			_this.apiGetTableData()
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
			})
		},
		methods: {
			/** --------> 方法介绍: 刷新
			author:郑凯 创建时间:2021-1-25 13:43:30 */
			thisReload() {
				_this.tableHeight = document.getElementById("tem_body").offsetHeight;
				_this.apiGetTableData();
			},

			/** ->  获取table列表
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-24 11:25:02 */
			apiGetTableData() {
				_this._getApi('/wy/coupon/getList', {
					page: _this.pageNumber,
					limit: _this.pageSize,
					residence_id: _this.upPageParams.community.id
				}).then(function(res) {
					console.log(res)
					_this.tableData = res.data.list;
					_this.tableLoading = false;
				}).catch(function(erre) {

				})
			},

			/** ->  分页
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-24 11:06:54 */
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			},

			/** ->  禁用、开启
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-24 16:03:21 */
			apiBan(obj) {
				_this.clickRowId = obj.id;
				_this.elButtnLoad = true;
				_this._postApi('/wy/coupon/updateStatus', {
					id: obj.id
				}).then(function(res) {
					_this.elButtnLoad = false;
					_this.apiGetTableData();
				}).catch(function(erre) {

				})
			},

			/** ->  删除
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-24 16:53:44 */
			deleteA(data) {
				const h = _this.$createElement;
				_this.$msgbox({
					title: '提醒',
					message: h('p', null, [
						h('span', null, '确定删除 '),
						h('span', {
							style: 'color: teal'
						}, data.name),
						h('span', null, '  吗？ ')
					]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							_this._postApi('/wy/coupon/delete', {
								id: data.id
							}).then(function(res) {
								done();
								setTimeout(() => {
									instance.confirmButtonLoading = false;
									_this.apiGetTableData();
								}, 300);
								
							}).catch(function(erre) {
							
							})
						} else {
							done();
						}
					}
				}).then(action => {
					
				});
			},

			/** ->  添加优惠券
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-24 11:02:11 */
			openDialogAdd() {
				_this.addDialog = true;
			},

			/** ->  组价事件监听
			AUTHOR:郑凯 
			CREATE_TIME:2021-2-24 11:08:08 */
			handlerChildrenEvent(e) {
				if (e.type == 'suceess') {
					_this.apiGetTableData();
					_this.addDialog = false;
				}
			},
		}
	}
</script>
